
.bagItem {
   display: grid;
   grid-template-columns: 1fr 10fr;
   gap: 20px;
   margin: 50px;
   /* border-bottom: solid 1px gray; */
   max-width: 600px;
}

.bag {
   display: grid;
   grid-template-columns: repeat(2, 1fr);

}

.bagImgCover{
   max-width: 150px;
   cursor: pointer;
}

.checkOutAside {
   max-width: 400px;
   text-align: center;
   margin: 0 auto;
}

.bagSubtotal{
   display: flex;
   justify-content: space-between;
  
}

.checkOut{
   background-color: #ebebeb;
   padding: 30px;
   position: sticky;
   top: 20px;
}

.checkOutButton {
   width: 340px
}



@media screen and (max-width: 900px) {
   .bag {
      display: block;
   }
}

@media screen and (max-width: 770px) {
   .checkOutButton {
      width: 240px
   }
}


