

.HomebannerImgContainer {
   /* position: relative; */
   height: 45vw;
   background-size: cover;
   padding: 0;
   /* background-position: center; */
}

.HomebannerImgContainerText {
   position: absolute;
   top: 50%;
   /* left: 00%; */
   /* transform: translate(-50%, -50%); */
   font-size: clamp(0.9rem, 3vw, 2rem);
   color: white;
   text-shadow: 1px 1px 2px black, 0 0 1em grey, 0 0 0.2em grey;
   max-width: 40vw;
   margin: 30px;
   /* text-shadow: 1px 1px 2px black, 0 0 1em grey, 0 0 0.2em grey; */
   /* opacity: 1.00;
   transition: opacity 3s;
	-moz-transition: opacity 3s;
	-webkit-transition: opacity 3s; */
   /* animation: shopbannertext_animation 3s infinite linear; */
}

.HomebannerImgContainerButton {
   border-radius: 0px;
   border: solid 2px;
   box-shadow: 1px 1px 1px black;
   font-size: clamp(0.9rem, 3vw, 2rem);
   color: white;
   text-shadow: 1px 1px 2px black, 0 0 1em grey, 0 0 0.2em grey;
}

.carousel {
   position: relative;

}

.carouselHeader {
   position: absolute;
   width: 90%;
   left: 50%;                         
   transform: translate(-50%, 0);
   z-index: 1000;
}

.carouselBanner {
   position: absolute;
}



.GamesContainerHome {
   display: grid;
   grid-template-columns: repeat(5, 1fr);
   gap: 20px;
   margin: 10px;
}

.GamesContainerHomeButton {
   border-radius: 0px;
   font-size: 1.2rem;
   margin: 20px auto;
}

@keyframes shopbannertext_animation {
    0% {
      opacity: 0.00;
    }
    50% {
      opacity: 0.50;
    }
    100% {
      opacity: 1.00;
    }
  }

.ImgСover {
   width: 100%;
}

.ShopContainer {

   width: 95%;
   margin: 0 auto;
   
}

.aboutSectionImg {
   width: 50%;
   /* display: inline-grid; */
   text-align: center;
  
   border: solid 2px black;
}

.aboutSectionImg img {
  
   width: 50%;
   animation: animatedSearch 3s linear infinite;

}

@keyframes animatedSearch {
   0%, 100% {
      transform: translateY(0);
   }
   50% {
      transform: translateY(-3rem);
   }
}


.aboutContainer {
   /* text-align: center; 
   display: inline-table;
   grid-template-columns: 50% 50% */
    /* margin-left: 2%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

/*****Adaptation************/
  
@media screen and (max-width: 960px) {
   .HomebannerImgContainer {
      height: 70vw;
   }

}

@media screen and (max-width: 770px) {
   .aboutSection {
      width: 100%;
   }

   .aboutContainer {
      flex-flow: column-reverse;
   }

   .GamesContainerHome {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin: 12px;
   }

   .HomebannerImgContainerText {
      top: 40%;
      max-width: 70vw;

   }
}