
.stagesSection h2 {
   text-align: center;
   font-size: 1.5rem;
}

.stagesSection {

}

.stagesContainer {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 10px;
   margin: 10px;
}

/*****Adaptation************/
  
@media screen and (max-width: 960px) {
   .stagesContainer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin: 10px;
   }
}

@media screen and (max-width: 770px) {
   .stagesContainer {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin: 10px;
   }
}