:root
 {
  --bs-blue: #000;

  --bs-primary: #000;

  --bs-primary-border-subtle: #000;
 
  --bs-link-color: #000;

  --bs-link-hover-color: #000;

  --bs-highlight-color: #212529;
}
.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;

  --bs-pagination-bg: #000;
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: red;
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: #000;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #000;
  --bs-pagination-active-border-color: #000;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}


.page-link:first-child {

  background-color: white;
  border: none;
  margin: 0;
  border-radius: 0;
  color: #000;
}

.page-link:focus {

  background-color: #000;
  border: none;
  box-shadow: none;
  color: #fff;
}

.page-link.active {
  background-color: #000;
  color: white;
  z-index: 3
}

.page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-item:first-child .active {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #000;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-item.active .page-link {
  z-index: 3;
  color: white;
  background-color: black;
}

