.picture {
   float: left;
   margin: 0px 40px;
   margin-left: 0px;
   max-width: 400px;
}

.postContainer {
   /* border: solid 2px black; */
   /* display: grid; */
   margin: 40px;
}

.postContainer .data{
   text-align: center;
}

.postContainer h2{
   text-align: center;
}

@media (max-width: 800px) {
   .picture {
      float: none;
      margin: 0px auto;
      text-align: center;
   }

   .postContainer {
      /* border: solid 2px black; */
      /* display: grid; */
      margin: 15px;
   }
  
}

