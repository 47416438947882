.L-header_light {
   display: block;
   align-items: center;

   width: 80%;
  
}

.L-header_container, .L-header_container_bottom{
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding-left: 20px;
}

.L-navbar {
   font-size: 0.8rem;
}

.L-header .contacts a {
   display: inline-block;
   font-family: Arial;
   font-size: 1.7rem;
   vertical-align: middle;
   margin-right: 2rem;
   text-decoration: none;
   text-shadow: 1px 0 1px #000, 
}

.L-header .contacts img {
   max-width: 25px
}

.L-header .L-navbar img {
   max-width: 25px
}

.L-header .navbar_logo img {
   max-width: 300px
}

.L-header .contacts a:hover {
   
   transform: scale(1.2, 1.2);
}

.L-navbar a {
   display: inline-block;
   font-family: Arial;
   font-size: 1rem;
   vertical-align: middle;
   margin-right: 2rem;
   margin-top: 10px;
   text-decoration: none;
   color: white;
}

.mobile_navbar {
   display: flex;
   flex-flow: column;
}

.mobile_navbar a {
   color: black;
}

.L-header .L-navbar a:hover {
   transform: scale(1.1, 1.1);
}

.menu_btn {
   display: none;
   max-width: 25px
}

.mobile_navbar_logo {
   display: none;
}

.mobil_icons {
   display: none;
   img {
      max-width: 30px
   }
}

.mobile_contacts img {
   max-width: 25px;
   margin-left: 10px;
}

.menu_btn:hover {
   max-width: 27px;
   cursor: pointer;
}

.detailed {
   font-size: 1.7rem;
   background: linear-gradient(rgb(3, 3, 72), rgb(14, 13, 13));
   color: white;
   padding: 7px;
   border-radius: 2px;
}

.detailed:hover {
   font-size: 1.8rem;
   background: linear-gradient(rgb(28, 28, 76), rgb(39, 37, 37));
   padding: 9px;
   border-radius: 3px;
   cursor: pointer;
}



@media (max-width: 1200px) {

}

@media (max-width: 770px) {
   .menu_btn {
       display: initial;
   }

   .mobil_icons {
      display: initial;
  }

  .mobile_navbar_logo {
   display: initial;
  }

  .contacts {
   display: none;
}

.L-header_container {
   padding: 15px;
   padding-bottom: 0px;
}

.L-header .L-navbar {
      display: none;
   }

   .L-header .L-header_container_bottom {
      display: none;
   }

   .L-header .L-navbar a {
       display: block;
       margin: 2rem 0;
       font-size: 1.8rem;
   }

   .L-header .L-navbar a:hover {
   
       transform: scale(1, 1);
       transform: translateX(10px);
       color: black;
       cursor: pointer;
   }

   .L-header .L-navbar.active {
       transform: scale(1);
       opacity: 1;
   }  
}
