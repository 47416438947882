
footer h2 {
   text-align: center;
   font-size: 1.5rem;
}

footer {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
}

.footerSection {
   background-color: white;
   /* width: 100%; */
   /*display: inline-table;
   text-align: left;
   margin-top: 0; */
   padding: 10px 50px;
   /* border: solid 2px black; */
}

.footerlink  {
   display: flex;
   flex-flow: column;
}

.footerlink a {
   color: black;
}

.footerlink a:hover {
   text-decoration: none;
   font-size: large;
   font-weight: 400;
}
.footerFormSubscriber{
   border-bottom: solid 2px black;
   justify-content: space-between;
}

.footerInputSubscriber{
   border: none;
   width: 80%;
   max-width: 80%;
   margin: 0;
}
.footerInputSubscriber:focus{
   border: none;
   box-shadow: none;
}

.footer_icons img{
   max-width: 25px;
   margin: 20px 10px;
   margin-left: 0px;
}

.futerMenuIconDown {
   display: none;
}

.futerMenuIconDownHide {
   display: none;
   opacity: 0;
} 

/*****Adaptation************/
  
@media screen and (max-width: 1060px) {
   footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      
   }
}

@media screen and (max-width: 770px) {

   footer {
      display: grid;
      grid-template-columns: 1fr;
   }
   .footerSection {
      width: 100%;
      border-bottom: solid 1px #D3D3D3;
   }

   .footerSectionItemHide{
      display: none;
   }

   .footerSectionItemVisible{
      display: block;
   }

   .futerMenuIconDown {
      display: block;
   }

   .futerMenuIconDownHide {
      display: none;
   }

   .footerSection {
      text-align: center;
   }
 

   .footerSection h2 {
      display: grid;
      grid-template-columns: 100fr 1fr;
   }

   .footerSection h2:hover {
      cursor: pointer;
   }

   .footerSection  h2:first-child {
      text-align: center;
    }
}