
.GamesContainerBlog {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 20px;
   margin: 50px;
}

.PostsContainerBlog {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 20px;
   margin: 50px;
}

.PostsPagination{
   justify-self: center;
	align-self: center;
}

@media screen and (max-width: 960px) {
   .PostsContainerBlog {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin: 30px;
   }
}

@media screen and (max-width: 770px) {
   .PostsContainerBlog {
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin: 20px;
   }

   .GamesContainerBlog {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
      margin: 15px;
   }
}


