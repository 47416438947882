.ImgСoverPost {
   object-fit: cover;
   width: 100%;
   height: 20vw;
}

.RandomPostList{
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: 30px;
   margin: 15px;
}



@media screen and (max-width: 770px) {
   .RandomPostList{
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
   }

   .ImgСoverPost {
      height: 40vw;
   }
} 