.ModalGameContainer {
    display: flex;
    justify-content: center;

}

.ModalImgСontainer {
   width: 40%;
   text-align: center;

}

.ModalImgСover {
   width: 100%;
}

.ModalDescriptionСontainer {
   width: 60%;
   padding: 0px 50px;
   vertical-align: top; 
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.ModalDescriptionСontainer h2 {
   padding: 0;
}

.ModalLogo {
   margin-bottom: 0;
}

.ModalButtons {
  
}

.VisibleButton {
   display: block;
}

.UnvisibleButton {
   display: none;
}




/*****Adaptation************/
  
@media screen and (max-width: 960px) {

}

@media screen and (max-width: 770px) {
   .ModalDescriptionСontainer {
      width: 100%;
      padding: 0px 15px;
   }

   .ModalDescriptionСontainerH2 {
      font-size: 1.4rem;
      text-align: center;
   }

   .ModalLogo {
      text-align: center;
   }

   .ModalImgСontainer {
      width: 80%;
      margin: 10px auto;
   }

   .ModalGameContainer {
      flex-flow: column;
   }

   .ModalDescriptionСontainerPrice {
      text-align: center;
   }
}