

.aboutHomeSection {
   background-color: white;
   width: 70%;
   
   text-align: right;
   /* margin-top: 0; */
   padding: 10px 30px;
   
   h2 {padding-left: 0%;}
   
}

.aboutHomeSectionImg {
   width: 30%;
   /* display: inline-grid; */
   text-align: center;
  
   border: none;
}

.aboutHomeSectionImg img {
  
   width: 50%;
   margin: 20px auto;
   /* animation: animatedSearch 3s linear infinite; */

}

@keyframes animatedSearch {
   0%, 100% {
      transform: translateY(0);
   }
   50% {
      transform: translateY(-3rem);
   }
}


.aboutHomeContainer {
   /* text-align: center; 
   display: inline-table;
   grid-template-columns: 50% 50% */
    /* margin-left: 2%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

/*****Adaptation************/
  
@media screen and (max-width: 960px) {

}

@media screen and (max-width: 770px) {
   .aboutHomeSection {
      width: 100%;
      text-align: center;
   }

   .aboutHomeContainer {
      flex-flow: column-reverse;
   }
}