.EmptyBagContainer {
   max-width: 700px;
   margin: 40px auto;
   
}


.EmptyBagByNow h2 {
   text-align: center;
   font-size: 1.5rem;
}

.EmptyBagByNow {
   
   width: 100%;
   min-height: 300px;
   text-align: center;
   padding: 80px 10px;
   outline: solid 3px black;
   outline-offset:-20px;
   background-color: #ebebeb;
}

.EmptyBagByNowButton {
   border-radius: 0%;
   margin-bottom: 5px;
}

/*****Adaptation************/
  
@media screen and (max-width: 960px) {
   
}

@media screen and (max-width: 770px) {
   
}