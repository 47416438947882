.list-group-item {

  background-color: white;
}

.list-group-item:hover {

  background-color: white;
}

