@font-face {
  font-family: 'Inconsolata';
  src: local('Inconsolata'),
		url('./font/Inconsolata-VF.ttf'),
		url('./font/Inconsolata-VF.eot');
  font-weight: 275 900;
  font-stretch: 50% 200%;
}

.loaderText {
  width: 250px;
  height: 300px;
  padding-top: 40px;
}

.loaderText {
  font-size: 1.5rem;
  white-space: nowrap;
  width: 200px;
  margin: auto;
  font-family: 'Inconsolata';
  text-align: center;
  position: relative;
}

.variable {
  display: flex;
  font-variation-settings: "wght"275, "wdth"50;
  text-align: center;
  -webkit-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99);
  -moz-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99); /* FF <= 15 */
  -o-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99); /* Opera 12 */
  -ms-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99);
  animation: swapthings 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99);
}

.load {
  -webkit-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99);
  -moz-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99); /* FF <= 15 */
  -o-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99); /* Opera 12 */
  -ms-animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99);
  animation: swapthings2 3s infinite both cubic-bezier(0.17, 0.04, 0.04, 0.99);
}

.loaderBatch {
  position: absolute;
  width: 190px;
  top: 0px;
  left: -20px;
  -webkit-animation: swapbatch 3s infinite both;
  -moz-animation: swapbatch 3s infinite both; /* FF <= 15 */
  -o-animation: swapbatch 3s infinite both; /* Opera 12 */
  -ms-animation: swapbatch 3s infinite both;
  animation: swapbatch 3s infinite both
}


@keyframes swapthings {
  0% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  50% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  100% {
    font-variation-settings: "wght"275, "wdth"50;
  }
}

@keyframes swapthings2 {
  0% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  50% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  100% {
    font-variation-settings: "wght"900, "wdth"200;
  }
}

@keyframes swapbatch {
  0% {
      left: -20px;
  }

  40% {
      left: 10px;
  }

  80% {
    left: -20px;
  }

  100% {
      left: -20px;
  }
}

@-webkit-keyrames swapthings {
  0% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  50% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  100% {
    font-variation-settings: "wght"275, "wdth"50;
  }
}

@-webkit-keyrames swapthings2 {
  0% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  50% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  100% {
    font-variation-settings: "wght"900, "wdth"200;
  }
}

@-webkit-keyrames swapbatch {
  0% {
      left: -20px;
  }

  40% {
      left: 10px;
  }

  80% {
    left: -20px;
  }

  100% {
      left: -20px;
  }
}

@-moz-keyrames swapthings {
  0% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  50% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  100% {
    font-variation-settings: "wght"275, "wdth"50;
  }
}

@-moz-keyrames swapthings2 {
  0% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  50% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  100% {
    font-variation-settings: "wght"900, "wdth"200;
  }
}

@-moz-keyrames swapbatch {
  0% {
      left: -20px;
  }

  40% {
      left: 10px;
  }

  80% {
    left: -20px;
  }

  100% {
      left: -20px;
  }
}

@-ms-keyrames swapthings {
  0% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  50% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  100% {
    font-variation-settings: "wght"275, "wdth"50;
  }
}

@-ms-keyrames swapthings2 {
  0% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  50% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  100% {
    font-variation-settings: "wght"900, "wdth"200;
  }
}

@-ms-keyrames swapbatch {
  0% {
      left: -20px;
  }

  40% {
      left: 10px;
  }

  80% {
    left: -20px;
  }

  100% {
      left: -20px;
  }
}

@-o-keyrames swapthings {
  0% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  50% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  100% {
    font-variation-settings: "wght"275, "wdth"50;
  }
}

@-o-keyrames swapthings2 {
  0% {
    font-variation-settings: "wght"900, "wdth"200;
  }

  50% {
    font-variation-settings: "wght"275, "wdth"50;
  }

  100% {
    font-variation-settings: "wght"900, "wdth"200;
  }
}

@-o-keyrames swapbatch {
  0% {
      left: -20px;
  }

  40% {
      left: 10px;
  }

  80% {
    left: -20px;
  }

  100% {
      left: -20px;
  }
}