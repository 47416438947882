.subscriber {
    background-color: #ebebeb;
    text-align: center;
 }

 h2 {
    padding: 20px;
    font-weight: 400;
 }

 p {
    font-weight: 400;
 }

 

 .form {
    margin: 0 auto;
    padding: 20px;
    max-width: 25vw;
    min-width: 400px;
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
 }

.form-control {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border-radius: 0px;  
};

.btn .btn-dark {
   border-radius: 0px;
   font-size: 0.9rem;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
}

.form-control:focus{
 border: solid 1px black;
 box-shadow: none;
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
}

 @media screen and (max-width: 770px) {
   .form {
      margin: 0 auto;
      padding: 10px;
      max-width: 25vw;
      min-width: 350px;
   }
}

