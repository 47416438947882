.sortBar {
   margin-bottom: 20px;
   text-align: right;
}

@media screen and (max-width: 770px) {
   .sortBar {
      margin: 20px;
      text-align: center;
   }
} 


