.searchItem {
   display: grid;
   grid-template-columns: 1fr 10fr;
   gap: 20px;
   margin: 50px;
   border-bottom: solid 1px gray;
}

.searchItem:last-child {
   border-bottom: none;
}

.search {

}

.searchImgCover{
   max-width: 150px;
   cursor: pointer;
}




@media screen and (max-width: 900px) {

}

@media screen and (max-width: 770px) {
   .searchItem {
      display: flex;
      flex-flow: column;
      text-align: center;
      padding-bottom: 20px;
      
   }
}


