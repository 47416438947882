.GamesContainerShop {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 10px;
   margin: 10px;
}

.ShopbannerImgContainer {
   position: relative;
}

.ShopbannerImgContainerText {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: clamp(1rem, 4vw, 4rem);
   color: white;
   text-shadow: 1px 1px 2px black, 0 0 1em grey, 0 0 0.2em grey;
   /* opacity: 1.00;
   transition: opacity 3s;
	-moz-transition: opacity 3s;
	-webkit-transition: opacity 3s; */
   animation: shopbannertext_animation 4s linear;
}


@keyframes shopbannertext_animation {
    0% {
      opacity: 0.00;
    }
    50% {
      opacity: 0.50;
    }
    100% {
      opacity: 1.00;
    }
  }

.ImgСover {
   width: 100%;
}

.btn-primary {
   background-color: black;
}

.ShopContainer {

   width: 95%;
   margin: 0 auto;
   
}

.aboutSectionImg {
   width: 50%;
   /* display: inline-grid; */
   text-align: center;
  
   border: solid 2px black;
}

.aboutSectionImg img {
  
   width: 50%;
   animation: animatedSearch 3s linear infinite;

}

@keyframes animatedSearch {
   0%, 100% {
      transform: translateY(0);
   }
   50% {
      transform: translateY(-3rem);
   }
}


.aboutContainer {
   /* text-align: center; 
   display: inline-table;
   grid-template-columns: 50% 50% */
    /* margin-left: 2%; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.ShopPagination{
   justify-self: center;
	align-self: center;
}


/*****Adaptation************/
  
@media screen and (max-width: 960px) {
   .GamesContainerShop {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin: 10px;
   }


}

@media screen and (max-width: 770px) {
   .GamesContainerShop {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      margin: 20px 50px;
   }
}