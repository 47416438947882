

.gameplay_aboutSection {
   width: 50%;
   text-align: left;
   padding: 10px 50px;
   h2 {padding-left: 0%;}
}

.gameplay_aboutSectionImg {
   width: 50%;
   text-align: center;
}

.gameplay_aboutSectionImg img {
   width: 40%;
   animation: gameplay_animatedSearch 4s linear infinite;
}

@keyframes gameplay_animatedSearch {
   0%, 100% {
      transform: translateY(0);
      transform: translateX(0);
   }
   40% {
      transform: translateY(-2rem);
   }

   70% {
      transform: translateX(-2rem);
   }
}


.gameplay_aboutContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*****Adaptation************/
  
@media screen and (max-width: 960px) {
   .gameplay_aboutSectionImg img {
      width: 60%;
      animation: gameplay_animatedSearchSm 4s linear infinite;
   }

   @keyframes gameplay_animatedSearchSm {
      0%, 100% {
         transform: translateX(-1rem);
      }
      50% {
         transform: translateX(1rem);
      }

   }
   .gameplay_aboutSection {
      width: 100%;
   }

   .gameplay_aboutContainer {
      flex-flow: column-reverse;
   }
}

@media screen and (max-width: 770px) {
   
}