
.ByNow h2 {
   text-align: center;
   font-size: 1.5rem;
}

.ByNow {
   
   width: 100%;
   display: inline-table;
   text-align: center;
   /* margin: 10px 10px ; */
   padding: 10px 10px;
   outline: solid 3px black;
   outline-offset:-10px;
   background-color: #ebebeb;
}

.ByNowButton {
   border-radius: 0%;
   margin-bottom: 5px;
}

/*****Adaptation************/
  
@media screen and (max-width: 960px) {
   
}

@media screen and (max-width: 770px) {
   
}