.header {
   /* position: fixed; */
   height: 5%;
   top: 0;
   left: 0;
   right: 0;
   /* box-shadow: 0rem 0.5rem 1rem rgb(162, 133, 80); */
   /* padding: 2rem 10%; */
   display: block;
   /* justify-content: space-between; */
   align-items: center;
   z-index: 1000;
   width: 100%;
}

.header_container, .header_container_bottom{
   width: 100%;
   display: flex;
   justify-content: space-between;
}

.navbar {
   font-size: 0.8rem;
}

header .contacts a {
   display: inline-block;
   font-family: Arial;
   font-size: 1.7rem;
   vertical-align: middle;
   margin-right: 2rem;
   text-decoration: none;
   color: black;
   text-shadow: 1px 0 1px #000, 
}

header .contacts img {
   max-width: 25px
}

header .navbar img {
   max-width: 25px
}

header .navbar_logo img {
   max-width: 300px
}

.header .contacts a:hover {
   
   transform: scale(1.2, 1.2);
}

header .navbar a {
   display: inline-block;
   font-family: Arial;
   font-size: 1rem;
   vertical-align: middle;
   margin-right: 2rem;
   text-decoration: none;
   color: black;
}

.mobile_navbar {
   display: flex;
   flex-flow: column;
}

.mobile_navbar a {
   color: black;
}

.header .navbar a:hover {
   transform: scale(1.1, 1.1);
}

.menu_btn {
   display: none;
   max-width: 25px
}

.mobile_navbar_logo {
   display: none;
}

.mobil_icons {
   display: none;
   img {
      max-width: 30px
   }
}

.mobile_contacts img {
   max-width: 25px;
   margin-left: 10px;
}

.menu_btn:hover {
   max-width: 27px;
   cursor: pointer;
}

.detailed {
   font-size: 1.7rem;
   background: linear-gradient(rgb(3, 3, 72), rgb(14, 13, 13));
   color: white;
   padding: 7px;
   border-radius: 2px;
}

.detailed:hover {
   font-size: 1.8rem;
   background: linear-gradient(rgb(28, 28, 76), rgb(39, 37, 37));
   padding: 9px;
   border-radius: 3px;
   cursor: pointer;
}


@media (max-width: 1200px) {

}

@media (max-width: 770px) {
   .menu_btn {
       display: initial;
   }

   .mobil_icons {
      display: initial;
  }

  .mobile_navbar_logo {
   display: initial;
  }

  .contacts {
   display: none;
}

.header_container {
   padding: 15px;
   padding-bottom: 0px;
}

   .header .navbar {
      display: none;
   }

   .header .header_container_bottom {
      display: none;
   }

   .header .navbar a {
       display: block;
       margin: 2rem 0;
       font-size: 1.8rem;
   }

   .header .navbar a:hover {
   
       transform: scale(1, 1);
       transform: translateX(10px);
       color: black;
       cursor: pointer;
   }

   .header .navbar.active {
       transform: scale(1);
       opacity: 1;
   }  
}
